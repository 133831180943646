<template>
  <div>
        <CRow class="pt--2" v-if="false">
        <CCol class="" col="6">
            <CButton size="lg" @click="openCreateNewMango = true" color="customblue2">สร้างคอร์สใหม่</CButton>&nbsp;
            <CButton size="lg" @click="openUseOldCourseMango = true" color="outline-customblue2">ใช้งานคอร์สเดิม</CButton>
        </CCol>
        <CCol class="text-right" col="6">
          <popper
            trigger="clickToToggle"
            :options="{
              placement: 'bottom',
              modifiers: { offset: { offset: '0,0px' } }
            }">
            <div class="popper text-center" style="padding: 10px;">
              <CRow>
                <CCol col=3>
                  <b>แสดงเทอม</b>
                </CCol>
                <CCol col=9>
                  <CInputCheckbox
                    label="เทอมปัจจุบัน"
                    value="showPresentCheckBox"
                    :inline="true"
                    :checked="filterChecked.present"
                    @update:checked="checkFilter"
                  />
                  <CInputCheckbox
                    label="เทอมถัดไป"
                    value="showFutureCheckBox"
                    :inline="true"
                    :checked="filterChecked.future"
                    @update:checked="checkFilter"
                  />
                </CCol>
              </CRow>
            </div>
            <button class="btn btn-secondary btn-lg" slot="reference">
              <b>Filter<CIcon height=25 name="cil-filter"/></b>
            </button>
          </popper>
        </CCol>
    </CRow>

    <CreateNewMango v-if="false" :openModal="openCreateNewMango" @afterCloseModal="openCreateNewMango = false" @afterCreateMango="callMangoListAgain" />
    <UseOldCourseMango v-if="false" :openModal="openUseOldCourseMango" @afterCloseModal="openUseOldCourseMango = false" @afterCreateMango="callMangoListAgain" />
    <CRow>
      <CardsMangoSandbox :mangoObject="mangoList[0]" @afterDeleteEvent="callMangoListAgain"/>
      <CardsMango v-bind:key="mango.id"  v-for='mango in mangoList' :mangoObject="mango" @afterDeleteEvent="callMangoListAgain"/>
    </CRow>
    <CCard>
    </CCard>
  </div>
</template>
<script>
import CardsMango from './CardsMango'
import CardsMangoSandbox from './CardsMangoSandbox'
import CreateNewMango from './CreateNewMango'
import UseOldCourseMango from './UseOldCourseMango'
import Api from "@/services/Api"
import MangoApi from "@/services/MangoApi"
import Swal from 'sweetalert2'
import Popper from 'vue-popperjs'
import 'vue-popperjs/dist/vue-popper.css'

export default {
  name: 'Mangolist',
  components: {
    CardsMango,
    CardsMangoSandbox,
    // CreateMangoModal,
    UseOldCourseMango,
    CreateNewMango,
    'popper': Popper
  },
  data () {
    return {
      mangoListAll:[],
      mangoList:[],
      openCreateNewMango:false,
      openUseOldCourseMango:false,
      reg_Term:process.env.VUE_APP_REG_TERM,
      reg_Year:process.env.VUE_APP_REG_YEAR,
      filterChecked:{
        all:false,
        past:false,
        present:true,
        future:false
      },
      term:{
        presentTerm: "",
        futureTerm: "",
        pastYearForFuterTerm: "",
      }
    }
  },
  created() {
    this.calculateTerm()
    // this.checkGrant(localStorage.getItem('email'))
    // this.getMangoListByUser(localStorage.getItem('email'))
    // if(localStorage.getItem('Admin') === 'admin'){
    //   console.log("Get Event: Admin active")
    //   if(this.$route.query.geteventof){
    //     if(this.$route.query.geteventof == 'all'){
    //       console.log("Get Event: Get Event All")
    //       this.getEventListALl()
    //     }else{
    //       console.log("Get Event: Get Event of:",this.$route.query.geteventof)
    //       this.getEventListByOwner(this.$route.query.geteventof)
    //     }
    //   }else{
    //     console.log("Get Event: Get Event of yourself")
    //     this.getEventListByOwner(localStorage.getItem('email'))
    //   }
    // }else{
    //   this.getEventListByOwner(localStorage.getItem('email'))
    // }
  },
  methods: {
    calculateTerm () {
      this.term.presentTerm = `${this.reg_Term}/${this.reg_Year.slice(2, 4)}`
      this.reg_Term = parseInt(this.reg_Term)
      if(this.reg_Term < 3){
          this.term.futureTerm = `${this.reg_Term+1}/${this.reg_Year.slice(2, 4)}`
          this.term.pastYearForFuterTerm = `${this.reg_Term+1}/${parseInt(this.reg_Year.slice(2, 4))-1}`
      }else{
          this.term.futureTerm = `1/${parseInt(this.reg_Year.slice(2, 4))+1}`
          this.term.pastYearForFuterTerm = `1/${parseInt(this.reg_Year.slice(2, 4))}`
      }
    },
    async getMangoListByUser(email){
      console.log("Hi")
      let cmuitaccount_name = email.substring(0, email.lastIndexOf("@"))
      // let splitTerm = this.term.futureTerm.split("/")
      Swal.fire({
        text: 'กำลังโหลด...',
        allowOutsideClick: false
      })
      Swal.showLoading()
      let mangoList = await MangoApi.getMangoListByUser(cmuitaccount_name)
      if(mangoList){
        // this.mangoList = await this.filterOnlyThisTerm(mangoList, this.mangoListAll, splitTerm[0], "25"+splitTerm[1])
        this.mangoListAll = mangoList
        this.mangoList = await this.filterOnlyThisTerm(mangoList, this.reg_Term, this.reg_Year)
        if(localStorage.getItem('filterShowFuture') == 'true'){
          this.filterChecked.future = true
          let val = {target:{value:""}}
          val.target.value = "showFutureCheckBox"
          this.checkFilter (true, val)
        }
        if(localStorage.getItem('filterShowPast') == 'true'){
          this.filterChecked.past = true
        }
        console.log("getMangoListByUser(Component):",mangoList)
        Swal.close()
      }
    },
    async checkGrant(email){
      let grantList = await Api.checkGrant(email)
      let foundMangoGrant = false
      if(grantList){
        console.log("grantList(API):",grantList)
        for(let i=0;i<grantList.length;i++){
          if(grantList[i].grant_on === "mango"){
            foundMangoGrant = true
            break
          }
        }
        if(foundMangoGrant){
          this.getMangoListByUser(email)
        }else{
          this.$router.push({path: `/`})
        }
      }
    },
    // async filterOnlyThisTerm (mangoListAll) {
    //   for(let i = mangoListAll.length - 1; i >= 0; i--){
    //     console.log("filterOnlyThisTerm:",mangoListAll[i])
    //     // let fullSemester = this.reg_Term+this.reg_Year.slice(2, 4)
    //     if(mangoListAll[i].sis_course_id === null){
    //       mangoListAll.splice(i, 1)
    //     }else if(mangoListAll[i].sis_course_id.slice(0, 3) !== this.reg_Term+this.reg_Year.slice(2, 4)){
    //       console.log(mangoListAll[i].sis_course_id.slice(0, 3),this.reg_Term+this.reg_Year.slice(2, 4))
    //       console.log(mangoListAll[i].sis_course_id.slice(0, 3) !== this.reg_Term+this.reg_Year.slice(2, 4))
    //       mangoListAll.splice(i, 1)
    //     }
    //   }
    //   return mangoListAll
    // },
    async filterOnlyThisTerm (mangoListAll, term, year) {
      let newMangoList = []
      console.log("filterOnlyThisTerm:",mangoListAll)
      for(let i = mangoListAll.length - 1; i >= 0; i--){
        console.log("filterOnlyThisTerm:",mangoListAll[i].sis_course_id)
        if(mangoListAll[i].sis_course_id !== null && mangoListAll[i].sis_course_id !== undefined){
          if(mangoListAll[i].sis_course_id.slice(0, 3) === term+year.slice(2, 4)){
            newMangoList.push(mangoListAll[i])
          }
        }else{
          console.log("sis_course_id null")
        }
        
      }
      return newMangoList
    },
    async deleteOnlyThisTerm (mangoList, term, year) {
      // let newMoodleList = []
      for(let i = mangoList.length - 1; i >= 0; i--){
        console.log("filterOnlyThisTerm:",mangoList[i])
        // let fullSemester = this.reg_Term+this.reg_Year.slice(2, 4)
        if(mangoList[i].sis_course_id.slice(0, 3) === term+year.slice(2, 4)){
          console.log(mangoList[i].sis_course_id.slice(0, 3),term+year.slice(2, 4))
          console.log(mangoList[i].sis_course_id.slice(0, 3) !== term+year.slice(2, 4))
          mangoList.splice(i, 1)
          // newMoodleList.push(moodleList[i])
        }
      }
      return mangoList
    },
    btnClicked (index) {
      this.$router.push({path: `events/${index}`})
    },
    async callMangoListAgain() {
      console.log('call again')
      this.openCreateNewMango = false
      this.openUseOldCourseMango = false
      await this.getMangoListByUser(localStorage.getItem('email'))
    },
    async checkFilter (event, val) {
      let mangoListAfterAppend = []
      let mangoListAfterDelete = []
      let splitTerm = []
      if(event){
        console.log(val.target.value)
        switch (val.target.value) {
          case "showFutureCheckBox":
            console.log("MangoListAll:",this.mangoListAll)
            localStorage.setItem('filterShowFuture', true)
            splitTerm = this.term.futureTerm.split("/")
            mangoListAfterAppend = await this.filterOnlyThisTerm(this.mangoListAll, splitTerm[0], "25"+splitTerm[1])
            this.mangoList = this.mangoList.concat(mangoListAfterAppend)
            break
          case "showPresentCheckBox":
            console.log("MangoListAll:",this.mangoListAll)
            localStorage.setItem('filterShowPresent', true)
            splitTerm = this.term.presentTerm.split("/")
            mangoListAfterAppend = await this.filterOnlyThisTerm(this.mangoListAll, splitTerm[0], "25"+splitTerm[1])
            this.mangoList = this.mangoList.concat(mangoListAfterAppend)
            break
          case "showPastCheckBox":
            
            break
          case "showAllCheckBox":
            
            break
          default:
            break
        }
      }else{
        console.log(val.target.value)
        switch (val.target.value) {
          case "showFutureCheckBox":
            console.log("MangoList:",this.mangoList)
            localStorage.setItem('filterShowFuture', false)
            splitTerm = this.term.futureTerm.split("/");
            mangoListAfterDelete = await this.deleteOnlyThisTerm(this.mangoList, splitTerm[0], "25"+splitTerm[1])
            this.mangoList = mangoListAfterDelete
            break
          case "showPresentCheckBox":
            console.log("MangoList:",this.mangoList)
            localStorage.setItem('filterShowPresent', false)
            splitTerm = this.term.presentTerm.split("/");
            mangoListAfterDelete = await this.deleteOnlyThisTerm(this.mangoList, splitTerm[0], "25"+splitTerm[1])
            this.mangoList = mangoListAfterDelete
            break
          case "showPastCheckBox":
            
            break
          case "showAllCheckBox":
            
            break
          default:
            break
        }
      }
    }
  }
}
</script>
