<template>
<div>
    <!-- <CModal
        title="ใช้งานคอร์สเดิม"
        show.sync="false"
        @update:show="closeModal"
        :centered="true"
    >
    <CRow v-show="!loadMoodleSuccess">
        <CCol col=12 class="text-center">
            <div  class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </CCol>
        <CCol col=12 class="text-center">
            กำลังโหลด
        </CCol>
    </CRow>
    <CCollapse :show="loadMoodleSuccess" >
        <CRow>
            <CCol>
                <h5><b>เลือกกระบวนวิชาเดิมที่ต้องการใช้เป็นต้นแบบ</b></h5>
            </CCol>
        </CRow>
        <CRow >
            <CCol class="text-center">
                <CSelect
                    :options="moodleCourseLists"
                    placeholder="เลือกกระบวนวิชา..."
                    @update:value="moodleCourseValidator"
                />
            </CCol>
        </CRow>
        <CRow v-show="loadingReg">
                <CCol col=12 class="text-center">
                    <div  class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </CCol>
                <CCol col=12 class="text-center">
                    กำลังดึงข้อมูลจากสำนักทะเบียน
                </CCol>
        </CRow>
        <CCollapse :show="loadRegSuccess">
            <CRow>
                <CCol >
                    <h5><b>เลือกกระบวนวิชาที่ต้องการสร้างและนำเข้าเนื้อหา</b></h5>
                </CCol>
            </CRow>
            <CRow >
                <CCol class="text-center">
                    <CSelect
                        :options="regCourseLists"
                        placeholder="เลือกกระบวนวิชา..."
                        @update:value="regCourseValidator"
                    />
                </CCol>
            </CRow>
            <CRow v-show="loadingSections">
                <CCol col=12 class="text-center">
                    <div  class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                </CCol>
                <CCol col=12 class="text-center">
                    กำลังค้นหาSection
                </CCol>
            </CRow>
            <CRow>
                <CCol class="text-center">
                    <CCollapse :show="cardCollapseSection" >
                        <CCol sm="12">
                            <CCard body-wrapper>
                                <CRow>
                                    <CCol sm="12">
                                        <h5>เลือก Section</h5>
                                    </CCol>
                                </CRow>
                                <CRow>
                                    <CCol sm="12">
                                    <CInputCheckbox
                                        label="เลือกทั้งหมด"
                                        @update:checked="checkAllSectionFunc"
                                        :checked="checkAllSection"
                                        value="CheckAkk"
                                    />
                                    <CInputCheckbox v-bind:key="name" v-for="(value, name) in CourseSectionLists"
                                        :label="name"
                                        @update:checked="checkSomeSection"
                                        :checked="checkAllSection"
                                        :value="value.type+':'+name"
                                    />
                                </CCol>
                                </CRow>
                            </CCard>
                        </CCol>
                    </CCollapse>
                    <CCollapse :show="cardCollapseSectionNotSelected" >
                        <CCol sm="12">
                            <CCard body-wrapper class="text-danger">
                                <CRow>
                                    <CCol sm="12">
                                        <h6><CIcon :height="25" name="cil-warning"/>Section ยังไม่ถูกเลือก</h6>
                                    </CCol>
                                </CRow>
                            </CCard>
                        </CCol>
                    </CCollapse>
                    <CCollapse :show="cardCollapseSectionNotFound" >
                        <CCol sm="12">
                            <CCard body-wrapper>
                                <CRow>
                                    <CCol sm="12">
                                        <h5><CIcon :height="50" name="cil-x"/>ไม่พบกระบวนวิชาในสำนักทะเบียน</h5>
                                    </CCol>
                                </CRow>
                            </CCard>
                        </CCol>
                    </CCollapse>
                </CCol>
            </CRow>
        </CCollapse>
        <CAlert
            color="warning"
            closeButton
            :show.sync="regNotSelectAlert"
        >
            กระบวนวิชายังไม่ถูกเลือก
        </CAlert>
        <CAlert
            color="warning"
            closeButton
            :show.sync="sectionNotSelectAlert"
        >
            Section ยังไม่ถูกเลือก
        </CAlert>
        <CCollapse :show="showCreateBtn">
            <CRow>
                <CCol class="text-center">
                    <CButton @click="beforeupdateMoodleCourse" color="pastelpurple2">สร้างคอร์ส</CButton>
                </CCol>
            </CRow>
        </CCollapse>
    </CCollapse>
        <template #footer>
            <CButton size="sm" @click="closeModal" color="secondary">Close</CButton>
        </template>
    </CModal> -->
    <CModal
        :title="titleName"
        :show.sync="openCreateModal"
        @update:show="closeModal"
        :centered="true"
    >        
        <transition name="fade" mode="out-in">
            <div>
            <div v-if="showSelectCurrentOrFutureTerm">
                <CRow>
                    <CCol sm=12 md=6 xl=6>
                        <center>
                            <CButton size="lg" @click="showCreateCurrentTermFunc" color="outline-customblue2" style="width: 200px;height:200px">
                                <h1><b>{{currentTerm}}</b></h1>
                                ใช้งานคอร์สเดิมกับเทอมปัจจุบัน
                            </CButton>
                        </center>
                    </CCol>
                    <CCol sm=12 md=6 xl=6>
                        <center>
                            <!-- <CButton size="lg" @click="showCreateFutureTermFunc" color="outline-customblue2" style="width: 200px;height:200px"> -->
                            <!-- <a href="https://tlic.atlassian.net/servicedesk/customer/portal/1/group/4/create/1" target="_blank"> -->
                                <CButton size="lg" disabled color="outline-customblue2" style="width: 200px;height:200px">
                                    <CBadge color="danger" class="mfs-auto">ยังไม่เปิดให้บริการ</CBadge>
                                    <h1><b>{{futureTerm}}</b></h1>
                                    ใช้งานคอร์สเดิมกับเทอมถัดไป
                                    <!-- ขอเปิดคอร์สล่วงหน้าเพื่อใช้งานในเทอมถัดไป -->
                                </CButton>
                            <!-- </a> -->
                        </center>
                    </CCol>
                </CRow>
            </div>
        
            <div v-if="showCreateCurrentTerm">
                <CRow v-show="!loadMangoSuccess">
                    <CCol col=12 class="text-center">
                        <div  class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    </CCol>
                    <CCol col=12 class="text-center">
                        กำลังโหลด
                    </CCol>
                </CRow>
                <div v-show="loadMangoSuccess">
                   <CRow>
                        <CCol>
                            <h5><b>เลือกกระบวนวิชาเดิมที่ต้องการใช้เป็นต้นแบบ</b></h5>
                        </CCol>
                    </CRow>
                    <CRow >
                        <CCol class="text-center">
                            <CSelect
                                :options="mangoCourseLists"
                                placeholder="เลือกกระบวนวิชา..."
                                @update:value="mangoCourseValidator"
                            />
                        </CCol>
                    </CRow> 
                </div>
                <CCollapse :show="loadingReg">
                    <CRow>
                        <CCol col=12 class="text-center">
                            <div  class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        </CCol>
                        <CCol col=12 class="text-center">
                            กำลังดึงข้อมูลจากสำนักทะเบียน
                        </CCol>
                    </CRow>
                </CCollapse>
                <CCollapse :show="loadRegSuccess" >
                    <CRow>
                        <CCol>
                            <h5><b>เลือกกระบวนวิชาที่ต้องการสร้างและนำเข้าเนื้อหา</b></h5>
                        </CCol>
                    </CRow>
                    <CRow >
                        <CCol class="text-center">
                            <!-- <CInput
                                type="number"
                                prepend="รหัสวิชา 6 หลัก"
                                description="เช่น 261214"
                                required
                                invalid-feedback="คุณกรอกรหัสวิชาไม่ถูกต้อง"
                                :is-valid="moodleCourseCodeValidator"
                            /> -->
                            <CSelect
                                :options="regCourseLists"
                                placeholder="เลือกกระบวนวิชา..."
                                @update:value="regCourseValidator"
                            />
                        </CCol>
                    </CRow>
                    <CAlert
                        color="warning"
                        closeButton
                        :show="true"
                    >
                        ไม่พบกระบวนวิชาที่ต้องการ <a v-on:click="$router.push({path: 'troubleshooting'})"><b>คลิกที่นี่</b></a>
                    </CAlert>
                    <CRow v-show="loadingSections">
                        <CCol col=12 class="text-center">
                            <div  class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        </CCol>
                        <CCol col=12 class="text-center">
                            กำลังค้นหาSection
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol class="text-center">
                            <CCollapse :show="cardCollapseSection" >
                                <CCol sm="12">
                                    <CCard body-wrapper>
                                        <CRow>
                                            <CCol sm="12">
                                                <h5>เลือก Section</h5>
                                            </CCol>
                                        </CRow>
                                        <!-- <span>Section ที่เลือก: {{ sectionCheckedVal }}</span> -->
                                        <!-- <li v-bind:key="name" v-for="(value, name) in CourseSectionLists">
                                            {{ name }}
                                        </li> -->
                                        <CRow>
                                            <CCol sm="12">
                                            <CInputCheckbox
                                                label="เลือกทั้งหมด"
                                                @update:checked="checkAllSectionFunc"
                                                :checked="checkAllSection"
                                                value="CheckAkk"
                                            />
                                            <CRow class="justify-content-center">
                                                <CCol col=2 class="text-left">
                                                    <CInputCheckbox v-bind:key="name" v-for="(value, name) in CourseSectionLists"
                                                        :label="name"
                                                        @update:checked="checkSomeSection"
                                                        :checked="checkAllSection"
                                                        :value="value.type+':'+name"
                                                    />
                                                </CCol>
                                            </CRow>
                                        </CCol>
                                        </CRow>
                                    </CCard>
                                </CCol>
                            </CCollapse>
                            <CCollapse :show="cardCollapseSectionNotSelected" >
                                <CCol sm="12">
                                    <CCard body-wrapper class="text-danger">
                                        <CRow>
                                            <CCol sm="12">
                                                <h6><CIcon :height="25" name="cil-warning"/>Section ยังไม่ถูกเลือก</h6>
                                            </CCol>
                                        </CRow>
                                    </CCard>
                                </CCol>
                            </CCollapse>
                            <CCollapse :show="cardCollapseSectionNotFound" >
                                <CCol sm="12">
                                    <CCard body-wrapper>
                                        <CRow>
                                            <CCol sm="12">
                                                <h5><CIcon :height="50" name="cil-x"/>ไม่พบกระบวนวิชาในสำนักทะเบียน</h5>
                                            </CCol>
                                        </CRow>
                                    </CCard>
                                </CCol>
                            </CCollapse>
                        </CCol>
                    </CRow>
                    <CAlert
                        color="warning"
                        closeButton
                        :show.sync="regNotSelectAlert"
                    >
                        กระบวนวิชายังไม่ถูกเลือก
                    </CAlert>
                    <CAlert
                        color="warning"
                        closeButton
                        :show.sync="sectionNotSelectAlert"
                    >
                        Section ยังไม่ถูกเลือก
                    </CAlert>
                    <!-- <CRow v-show="!loadingSections">
                        <CCol class="text-center">
                            <CButton @click="beforeupdateMoodleCourse" :disabled="false" color="pastelpurple2">สร้างคอร์ส</CButton>
                        </CCol>
                    </CRow> -->
                    <CCollapse :show="showCreateBtn">
                        <CRow>
                            <CCol class="text-center">
                                <CButton @click="beforeupdateMangoCourse" color="pastelpurple2">สร้างคอร์ส</CButton>
                            </CCol>
                        </CRow>
                    </CCollapse>
                    
                <!-- </div> -->
                </CCollapse>
                <CRow class="pt-1">
                    <CCol class="text-center">
                        <CButton size="sm" @click="showSelectCurrentOrFutureTermFunc" color="outline-danger">ย้อนกลับ</CButton>
                    </CCol>
                </CRow>
            </div>
            <div v-if="showCreateFutureTerm">
                <CRow v-show="!loadMangoSuccess">
                    <CCol col=12 class="text-center">
                        <div  class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    </CCol>
                    <CCol col=12 class="text-center">
                        กำลังโหลด
                    </CCol>
                </CRow>
                <div v-show="loadMangoSuccess">
                   <CRow>
                        <CCol>
                            <h5><b>เลือกกระบวนวิชาเดิมที่ต้องการใช้เป็นต้นแบบ</b></h5>
                        </CCol>
                    </CRow>
                    <CRow >
                        <CCol class="text-center">
                            <CSelect
                                :options="mangoCourseLists"
                                placeholder="เลือกกระบวนวิชา..."
                                @update:value="mangoCourseValidator"
                            />
                        </CCol>
                    </CRow> 
                </div>
                <CCollapse :show="loadingReg">
                    <CRow>
                        <CCol col=12 class="text-center">
                            <div  class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        </CCol>
                        <CCol col=12 class="text-center">
                            กำลังดึงข้อมูลจากสำนักทะเบียน
                        </CCol>
                    </CRow>
                </CCollapse>
                <!-- <div v-show="loadRegSuccess"> -->
                <CCollapse :show="loadRegSuccess" >
                    <CRow>
                        <CCol >
                            <h5><b>เลือกกระบวนวิชาที่ต้องการสร้างและนำเข้าเนื้อหา</b></h5>
                        </CCol>
                    </CRow>
                    <CAlert
                        color="warning"
                        closeButton
                        :show.sync="usePastYearForFuterTermAlert"
                    >
                    ตอนที่ท่านกำลังสร้างคอร์สเทอม {{futureTerm}} โดยอ้างอิงกระบวนวิชาจาก {{pastYearForFuterTerm}} ซึ่งการสร้างในรูปแบบนี้จะยัง<b>ไม่มีการนำเข้านักศึกษาให้อัตโนมัติในทันที</b>
                    </CAlert>
                    <CAlert
                        color="warning"
                        :show.sync="optionPastYearForFuterTermAlert"
                    >
                    หากไม่พบกระบวนวิชาในเทอมที่เลือก สามารถ<a v-on:click="usePastYearForFutureTermFunc"><b>คลิกที่นี่</b></a>เพื่อใช้งานคอร์สเดิมแบบอ้างอิงข้อมูลในปีก่อนหน้า
                    </CAlert>
                    
                    <CRow >
                        <CCol class="text-center">
                            <!-- <CInput
                                type="number"
                                prepend="รหัสวิชา 6 หลัก"
                                description="เช่น 261214"
                                required
                                invalid-feedback="คุณกรอกรหัสวิชาไม่ถูกต้อง"
                                :is-valid="moodleCourseCodeValidator"
                            /> -->
                            <CSelect
                                :options="regCourseLists"
                                placeholder="เลือกกระบวนวิชา..."
                                @update:value="regCourseValidator"
                            />
                        </CCol>
                    </CRow>
                    <CRow v-show="loadingSections">
                        <CCol col=12 class="text-center">
                            <div  class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                        </CCol>
                        <CCol col=12 class="text-center">
                            กำลังค้นหาSection
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol class="text-center">
                            <CCollapse :show="cardCollapseSection" >
                                <CCol sm="12">
                                    <CCard body-wrapper>
                                        <CRow>
                                            <CCol sm="12">
                                                <h5>เลือก Section</h5>
                                            </CCol>
                                        </CRow>
                                        <!-- <span>Section ที่เลือก: {{ sectionCheckedVal }}</span> -->
                                        <!-- <li v-bind:key="name" v-for="(value, name) in CourseSectionLists">
                                            {{ name }}
                                        </li> -->
                                        <CRow>
                                            <CCol sm="12">
                                            <CInputCheckbox
                                                label="เลือกทั้งหมด"
                                                @update:checked="checkAllSectionFunc"
                                                :checked="checkAllSection"
                                                value="CheckAkk"
                                            />
                                            <CRow class="justify-content-center">
                                                <CCol col=2 class="text-left">
                                                    <CInputCheckbox v-bind:key="name" v-for="(value, name) in CourseSectionLists"
                                                        :label="name"
                                                        @update:checked="checkSomeSection"
                                                        :checked="checkAllSection"
                                                        :value="value.type+':'+name"
                                                    />
                                                </CCol>
                                            </CRow>
                                        </CCol>
                                        </CRow>
                                    </CCard>
                                </CCol>
                            </CCollapse>
                            <CCollapse :show="cardCollapseSectionNotSelected" >
                                <CCol sm="12">
                                    <CCard body-wrapper class="text-danger">
                                        <CRow>
                                            <CCol sm="12">
                                                <h6><CIcon :height="25" name="cil-warning"/>Section ยังไม่ถูกเลือก</h6>
                                            </CCol>
                                        </CRow>
                                    </CCard>
                                </CCol>
                            </CCollapse>
                            <CCollapse :show="cardCollapseSectionNotFound" >
                                <CCol sm="12">
                                    <CCard body-wrapper>
                                        <CRow>
                                            <CCol sm="12">
                                                <h5><CIcon :height="50" name="cil-x"/>ไม่พบกระบวนวิชาในสำนักทะเบียน</h5>
                                            </CCol>
                                        </CRow>
                                    </CCard>
                                </CCol>
                            </CCollapse>
                        </CCol>
                    </CRow>
                    <CAlert
                        color="warning"
                        closeButton
                        :show.sync="regNotSelectAlert"
                    >
                        กระบวนวิชายังไม่ถูกเลือก
                    </CAlert>
                    <CAlert
                        color="warning"
                        closeButton
                        :show.sync="sectionNotSelectAlert"
                    >
                        Section ยังไม่ถูกเลือก
                    </CAlert>
                    <CCollapse :show="showCreateBtn">
                        <CRow>
                            <CCol class="text-center">
                                <CButton @click="beforeupdateMangoCourse" color="pastelpurple2">สร้างคอร์ส</CButton>
                            </CCol>
                        </CRow>
                    </CCollapse>
                <!-- </div> -->
                </CCollapse>
                <CRow class="pt-1">
                    <CCol class="text-center">
                        <CButton size="sm" @click="showSelectCurrentOrFutureTermFunc" color="outline-danger">ย้อนกลับ</CButton>
                    </CCol>
                </CRow>
            </div>
            </div>
        </transition>
        <template #footer>
            <!-- <CButton @click="afterAcceptPolicy()" color="success">Accept</CButton> -->
            <CButton size="sm" @click="closeModal" color="secondary">Close</CButton>
        </template>
    </CModal>
</div>
</template>

<script>
import REGApi from "@/services/REGApi"
import MangoApi from "@/services/MangoApi"
import Swal from 'sweetalert2'
import _ from 'lodash'

export default {
    name: 'UseOldCourseMango',
    props:{
        openModal: Boolean
    },
    components: {
    },
    data () {
        return {
            // userEmail:'Dome.Potikanond@cmu.ac.th',
            // userEmail:'pawinee.nim@cmu.ac.th',
            userEmail:localStorage.getItem('email'),
            reg_Term:process.env.VUE_APP_REG_TERM,
            reg_Year:process.env.VUE_APP_REG_YEAR,
            openCreateModal: false,
            options: [ { value: 'js', label: 'JavaScript' }, { value: 'html', label: 'HTML' } ],
            regCourseLists: ['Not found'],
            regCourseSelected: "",
            regCourseSelectedName: "",
            regNotSelectAlert: false,
            sectionNotSelectAlert: false,
            cardCollapseSection: false,
            cardCollapseSectionNotSelected: false,
            cardCollapseSectionNotFound: false,
            loadingSections:false,
            checkAllSection: false,
            CourseSectionLists: [],
            sectionCheckedVal: [],
            loadingReg:false,
            loadRegSuccess:false,
            loadMangoSuccess:false,
            mangoCourseLists: ['Not found'],
            mangoCourseSelected: "",
            mangoCourseSelectedIdnumber: "",
            mangoCourseSelectedName: "",
            mangoNotSelectAlert: false,
            showCreateBtn: false,
            showCreateCurrentTerm: false,
            showCreateFutureTerm: false,
            showSelectCurrentOrFutureTerm: true,
            titleName: "ใช้งานคอร์สเดิม",
            currentTerm: "",
            futureTerm: "",
            pastYearForFuterTerm: "",
            onSelectedTerm: "",
        }
    },
    created() {
        this.calculateTerm()
        if(this.userEmail === "nuttapong.pongkam@cmu.ac.th"){
            this.userEmail = "arnan.s@cmu.ac.th"
        }
        // this.getMoodleListByUser(localStorage.getItem('email'))
        // this.getTeacherCourseFromReg(this.userEmail)
    },
    watch: { 
        openModal: function(newVal, oldVal) { // watch it
            console.log('Prop changed: ', newVal, ' | was: ', oldVal)
            this.openCreateModal = newVal
        }
    },
    methods: {
        calculateTerm () {
            this.currentTerm = `${this.reg_Term}/${this.reg_Year.slice(2, 4)}`
            this.reg_Term = parseInt(this.reg_Term)
            if(this.reg_Term < 3){
                this.futureTerm = `${this.reg_Term+1}/${this.reg_Year.slice(2, 4)}`
                this.pastYearForFuterTerm = `${this.reg_Term+1}/${parseInt(this.reg_Year.slice(2, 4))-1}`
            }else{
                this.futureTerm = `1/${parseInt(this.reg_Year.slice(2, 4))+1}`
                this.pastYearForFuterTerm = `1/${parseInt(this.reg_Year.slice(2, 4))}`
            }
        },
        closeModal () {
            this.openCreateModal = false
            this.$emit('afterCloseModal', false);
        },
        async beforeupdateMangoCourse () {
            if(this.regCourseSelected === ""){
                this.regNotSelectAlert = true
            }else{
                if(this.sectionCheckedVal.length == 0){
                    this.sectionNotSelectAlert = true
                }else{
                    this.regNotSelectAlert = false
                    this.sectionNotSelectAlert = false
                    console.log(this.regCourseSelected)
                    console.log(this.regCourseSelectedName)
                    console.log(this.sectionCheckedVal)
                    let cmuitaccount = localStorage.getItem('email')
                    let cmuitaccount_name = cmuitaccount.substring(0, cmuitaccount.lastIndexOf("@"))
                    let sectionJsonArr = []
                    for(let section of this.sectionCheckedVal){
                        console.log(section)
                        if(section.includes('lab')){
                            let sectionSplit = section.split(":")
                            let sectionJson = `000${sectionSplit[1]}`
                            sectionJsonArr.push(sectionJson)
                        }else if(section.includes('lec')){
                            let sectionSplit = section.split(":")
                            let sectionJson = `${sectionSplit[1]}000`
                            sectionJsonArr.push(sectionJson)
                        }
                    }
                    console.log("sectionJsonArr:",JSON.stringify(sectionJsonArr))
                    let termId = process.env.VUE_APP_CANVAS_THIS_TERMID
                    let termSplit = []
                    let timeStamp = {
                        start:"",
                        end:""
                    }
                    if(this.onSelectedTerm === this.pastYearForFuterTerm || this.onSelectedTerm === this.futureTerm){
                        termSplit = this.futureTerm.split("/")
                        timeStamp.start = process.env.VUE_APP_TIMESTAMP_START_NEXT_TERM
                        timeStamp.end = process.env.VUE_APP_TIMESTAMP_END_NEXT_TERM
                        termId = process.env.VUE_APP_CANVAS_NEXT_TERMID
                    }else{
                        termSplit = this.onSelectedTerm.split("/")
                        timeStamp.start = process.env.VUE_APP_TIMESTAMP_START_THIS_TERM
                        timeStamp.end = process.env.VUE_APP_TIMESTAMP_END_THIS_TERM
                    }
                    // let apiData = ({
                    //     "regCourse_selected": `${this.regCourseSelected}|${this.regCourseSelectedName}`,
                    //     "category_selected": "26",
                    //     "sectionArr": sectionJsonArr,
                    //     "create_by": {
                    //         "cmuitaccount_name": cmuitaccount_name,
                    //         "cmuitaccount": cmuitaccount,
                    //     }
                    // })
                    let apiData = ({
                        "course_id": this.mangoCourseSelected,
                        "course_idnumber": this.mangoCourseSelectedIdnumber,
                        "course_fullname": this.mangoCourseSelectedName,
                        "term_id": termId,
                        "reg_course_id": this.regCourseSelected,
                        "reg_section_arr": sectionJsonArr,
                        "term": termSplit[0],
                        "year": 25+termSplit[1],
                        "create_by": {
                            "cmuitaccount_name": cmuitaccount_name,
                            "cmuitaccount": cmuitaccount
                        }
                    })
                    // console.log("use old apiData:",apiData2)
                    this.updateMangoCourse(apiData)
                }
            }
        },
        async updateMangoCourse(jsonData){
            if(jsonData){
                console.log("inprogress")
                this.openCreateModal = false
                this.afterupdateMangoCourse('open','')
                let dataChecking = {
                    "term": jsonData.term,
                    "year": jsonData.year,
                    "regCourse_selected_id": jsonData.reg_course_id,
                    "sectionArr": jsonData.reg_section_arr
                }
                const checkCanBeCreate = await MangoApi.checkCanBeCreate(dataChecking)
                if(checkCanBeCreate.status){
                    let createMango = await MangoApi.updateMangoCourse(jsonData)
                    console.log("response:",createMango)
                    if(createMango){
                        try {
                            if(createMango.errors){
                                console.log(createMango.errors)
                                // this.afterupdateMangoCourse('error',createMango.errors.message)
                                if(createMango.errors.sis_source_id){
                                this.afterupdateMangoCourse(createMango.errors.sis_source_id[0].attribute, createMango.errors.sis_source_id[0].message)
                                }else{
                                this.afterupdateMangoCourse('error','เกิดข้อผิดพลาดที่ไม่ทราบ')
                                }
                            }else{
                            console.log("finish")
                            this.afterupdateMangoCourse('success')
                            }
                        }
                        catch(err) {
                            console.log("err:",err)
                            this.afterupdateMangoCourse('error','เกิดข้อผิดพลาดที่ไม่ทราบ')
                        }
                        
                    }else{
                        console.log("fail")
                        this.afterupdateMangoCourse('error','')
                    }
                }else{
                    console.log("full")
                    this.afterupdateMangoCourse('','จำนวนผู้ใช้งานเกินลิมิตที่กำหนดแล้ว')
                }
            }
        },
        async afterupdateMangoCourse(command,msg){
            let text = ""
            let title = ""
            let icon = ""
            let showLoading = false
            switch (command) {
                case 'open': 
                    title = "กำลังดำเนินการ"
                    showLoading = true
                    break;
                case 'success': 
                    icon = "success"
                    title = "เสร็จสิ้น"
                    text = "สร้างคอร์สเรียบร้อย ท่านสามารถเข้าสู่คอร์สได้ทันที<br><b>ระบบอาจใช้เวลาสักครู่ในการประมวลผลและนำเข้านักศึกษาให้ท่าน</b>"
                    break;
                case 'error': 
                    icon = "error"
                    title = "เกิดข้อผิดพลาด"
                    text = "การสร้าง event เกิดข้อผิดพลาด"
                    break;
                case 'shortnametaken': 
                    icon = "error"
                    title = "เกิดข้อผิดพลาด"
                    text = "มีคอร์สนี้อยู่แล้วในระบบ"
                    break;
                default:
                    icon = "error"
                    title = "เกิดข้อผิดพลาด"
                    text = msg
                    break;
            }
            
            Swal.fire({
                icon: icon,
                title: title,
                html: text,
                willClose: () => {
                    this.$emit('afterCreateMango', true)
                }
            })
            if(showLoading){
                Swal.showLoading()
            }
            // this.$emit('afterCreateMoodle', true);
        },
        mangoCourseValidator (val,event) {
            console.log(val)
            console.log(event)
            let valArr = val.split("|")
            this.mangoCourseSelected = valArr[0]
            this.mangoCourseSelectedIdnumber = valArr[1]
            this.mangoCourseSelectedName = valArr[2]
            this.mangoNotSelectAlert = false
            this.loadingReg = true
            this.getTeacherCourseFromReg(this.userEmail, this.onSelectedTerm)
        },
        regCourseValidator (val,event) {
            console.log(val)
            console.log(event)
            let valArr = val.split("|")
            this.regCourseSelected = valArr[0]
            this.regCourseSelectedName = valArr[1]
            this.regNotSelectAlert = false
            this.sectionNotSelectAlert = false
            this.showCreateBtn = false
            this.searchSection()
        },
        async searchSection () {
            console.log("searchSection")
            this.cardCollapseSectionNotSelected = false
            this.cardCollapseSection = false
            this.loadingSections = true
            // this.checkAllSectionFunc(true,"")
            // this.checkAllSectionFunc(false,"")
            this.checkAllSection = false
            this.sectionCheckedVal = []
            // if(this.regCourseSelected === ""){
            //     this.regNotSelectAlert = true
            // }else{
            //     let courseSections = await REGApi.getCourseSection(this.regCourseSelected)
            // }
            let termSplit = this.onSelectedTerm.split("/")
            let sectionList = await REGApi.getCourseSection(this.regCourseSelected, termSplit[0], "25"+termSplit[1])
            this.loadingSections = false
            if(sectionList.status === true){
                this.cardCollapseSectionNotFound = false
                this.cardCollapseSection = true
                // this.CourseSectionLists = sectionList.sections
                console.log("🚀 ~ searchSection ~ this.CourseSectionLists", sectionList.sections)
                console.log("🚀 ~ searchSection ~ this.CourseSectionLists", this.CourseSectionLists)
                let ordered = Object.keys(sectionList.sections).sort().reduce(
                    (obj, key) => { 
                        obj[key] = sectionList.sections[key]; 
                        return obj;
                    }, 
                    {}
                )
                console.log(ordered)
                this.CourseSectionLists = ordered
                // → '{"a":"baz","b":"foo","c":"bar"}'
                let newob = _.orderBy(sectionList.sections, 'name')
                console.log("🚀 ~ searchSection ~ newob", newob)
                for (let section in sectionList.sections) {
                    console.log("🚀 ~ searchSection ~ section", section)
                }
            }else{
                this.cardCollapseSection = false
                this.cardCollapseSectionNotFound = true
            }
        },
        async filterNotThisTerm (mangoListAll) {
            for(let i = mangoListAll.length - 1; i >= 0; i--){
              console.log("filterNotThisTerm:",mangoListAll[i])
              // let fullSemester = this.reg_Term+this.reg_Year.slice(2, 4)
              if(mangoListAll[i].sis_course_id !== null){
                if(mangoListAll[i].sis_course_id.slice(0, 3) === this.reg_Term+this.reg_Year.slice(2, 4)){
                    console.log(mangoListAll[i].sis_course_id.slice(0, 3),this.reg_Term+this.reg_Year.slice(2, 4))
                    console.log(mangoListAll[i].sis_course_id.slice(0, 3) !== this.reg_Term+this.reg_Year.slice(2, 4))
                    mangoListAll.splice(i, 1)
                }
              }else{
                console.log("sis_course_id null")
              }
            }
            return mangoListAll
        },
        async getMangoListByUser(email){
            console.log("getMangoListByUser")
            let cmuitaccount_name = email.substring(0, email.lastIndexOf("@"))
            let mangoList = await MangoApi.getMangoListByUser(cmuitaccount_name)
            this.mangoCourseLists = []
            if(mangoList){
                this.mangoList = await this.filterNotThisTerm(mangoList)
                // this.moodleCourseLists = moodleList
                for(let i=0;i<mangoList.length;i++){
                    // console.log(teacherRegCourse.courses[i])
                    let selectData = { value: `${mangoList[i].id}|${mangoList[i].sis_course_id}|${mangoList[i].name}|`, label: mangoList[i].name }
                    this.mangoCourseLists.push(selectData)
                }
                this.loadMangoSuccess = true
            }
        },
        async getTeacherCourseFromReg (email, term) {
            console.log("term:",term)
            let termSplit = term.split("/")
            console.log("termSplit[0], '25'+termSplit[1]",termSplit[0], "25"+termSplit[1])
            this.loadRegSuccess = false
            this.loadingReg = true
            let teacherRegCourse = await REGApi.getTeacherCourse(email, termSplit[0], "25"+termSplit[1])
            this.loadingReg = false
            this.regCourseLists = []
            if(teacherRegCourse){
                if(teacherRegCourse.status){
                    for(let i=0;i<teacherRegCourse.courses.length;i++){
                        // console.log(teacherRegCourse.courses[i])
                        let selectData = { value: `${teacherRegCourse.courses[i].no}|${teacherRegCourse.courses[i].title_long}`, label: `${teacherRegCourse.courses[i].no} - ${teacherRegCourse.courses[i].title_long}` }
                        if (this.regCourseLists.filter(function(e) { return e.value === `${teacherRegCourse.courses[i].no}|${teacherRegCourse.courses[i].title_long}`; }).length > 0) {
                            /* vendors contains the element we're looking for */
                            console.log('Duplicate')
                        }else{
                            this.regCourseLists.push(selectData)
                        }

                    }
                    // this.regCourseLists = teacherRegCourse.courses
                    console.log("getTeacherCourseFromReg:",teacherRegCourse)
                }else{
                    if(teacherRegCourse.message.includes("is not found")){
                        this.regCourseLists.push({value: '', label: `ข้อมูลของเทอม ${term} ยังไม่พบในสำนักทะเบียน`,disabled : true})
                    }
                }
                this.loadRegSuccess = true
            }
        },
        checkAllSectionFunc (event) {
            this.checkAllSection = event
            if(event){
                for(let section in this.CourseSectionLists){
                    let checkList = `${this.CourseSectionLists[section].type}:${section}`
                    console.log(checkList)
                    if(!this.sectionCheckedVal.includes(checkList)){
                        this.sectionCheckedVal.push(checkList)
                        console.log(this.sectionCheckedVal)
                    }else{
                        console.log("duplicate")
                    }
                }
            }else{
                for(let section in this.CourseSectionLists){
                    let checkList = `${this.CourseSectionLists[section].type}:${section}`
                    console.log(checkList)
                    if(this.sectionCheckedVal.includes(checkList)){
                        let index = this.sectionCheckedVal.indexOf(checkList);
                        if (index > -1) {
                            this.sectionCheckedVal.splice(index, 1);
                        }
                        console.log(this.sectionCheckedVal)
                    }else{
                        console.log("not found")
                    }
                }
            }
            if(this.sectionCheckedVal.length === 0){
                this.showCreateBtn = false
            }else{
                this.showCreateBtn = true
            }
        },
        checkSomeSection (event, val) {
            if(event){
                console.log(event,val.target.value)
                let checkList = val.target.value
                if(!this.sectionCheckedVal.includes(checkList)){
                    this.sectionCheckedVal.push(checkList)
                    console.log(this.sectionCheckedVal)
                }else{
                    console.log("duplicate")
                }
            }else{
                console.log(event,val.target.value)
                let checkList = val.target.value
                if(this.sectionCheckedVal.includes(checkList)){
                    let index = this.sectionCheckedVal.indexOf(checkList);
                    if (index > -1) {
                        this.sectionCheckedVal.splice(index, 1);
                    }
                    console.log(this.sectionCheckedVal)
                }else{
                    console.log("not found")
                }
            }
            if(this.sectionCheckedVal.length === 0){
                this.showCreateBtn = false
            }else{
                this.showCreateBtn = true
            }
        },
        showCreateCurrentTermFunc () {
            this.showSelectCurrentOrFutureTerm = false
            this.showCreateCurrentTerm = true
            this.titleName = `ใช้งานคอร์สเดิม [${this.currentTerm}]`
            this.onSelectedTerm = this.currentTerm
            // this.getTeacherCourseFromReg(this.userEmail, this.currentTerm)
            this.getMangoListByUser(localStorage.getItem('email'))
            this.clearSection()
        },
        showCreateFutureTermFunc () {
            this.showSelectCurrentOrFutureTerm = false
            this.showCreateFutureTerm = true
            this.optionPastYearForFuterTermAlert = true
            this.usePastYearForFuterTermAlert = false
            this.titleName = `ใช้งานคอร์สเดิม [${this.futureTerm}]`
            this.onSelectedTerm = this.futureTerm
            // this.getTeacherCourseFromReg(this.userEmail, this.futureTerm)
            this.getMangoListByUser(localStorage.getItem('email'))
            this.clearSection()
        },
        showSelectCurrentOrFutureTermFunc () {
            this.showCreateBtn = false
            this.showCreateCurrentTerm = false
            this.showCreateFutureTerm = false
            this.showSelectCurrentOrFutureTerm = true
            this.titleName = "สร้างคอร์สใหม่"
            this.loadRegSuccess = false
            this.clearSection()
        },
        clearSection () {
            // this.cardCollapseSectionNotSelected = true
            this.cardCollapseSection = false
            // this.checkAllSectionFunc(true,"")
            // this.checkAllSectionFunc(false,"")
            this.checkAllSection = false
            this.sectionCheckedVal = []
        },
        usePastYearForFutureTermFunc () {
            this.titleName = `ใช้งานคอร์สเดิมกับ [${this.futureTerm}] โดยอ้างอิงจากปี [${this.pastYearForFuterTerm}]`
            this.optionPastYearForFuterTermAlert = false
            this.usePastYearForFuterTermAlert = true
            this.onSelectedTerm = this.pastYearForFuterTerm
            this.getTeacherCourseFromReg(this.userEmail, this.pastYearForFuterTerm)
        },
    }
}
</script>
