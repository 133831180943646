import {LMS} from "@/axios.js"

LMS.defaults.timeout = 600000

export default {
    async getMangoListByUser(cmuitaccount){
        let response = await LMS.get(`api/GetMangoListByUser?cmuitaccount_name=${cmuitaccount}`)
        .then(res => {
          if (res.data) {
            // console.log("getCourseByOwner(API Service):",res.data);
            return res.data
          }
        })
        .catch(e => {
          console.log(e);
        });
        return response
    },
    async getEnrollStatusByLms(lmsType, courseId){
        let response = await LMS.get(`api/GetEnrollStatusByLms?lms_type=${lmsType}&course_id=${courseId}`)
        .then(res => {
          if (res.data) {
            console.log("getEnrollStatusByLms(API Service):",res.data);
            return res.data
          }
        })
        .catch(e => {
          console.log(e);
        });
        return response
    },
    async creatMangoCourse(data){
        console.log(data)
        let response = await LMS.post(`api/CreateMango`,data, {timeout: 600000})
        .then(res => {
            if (res.data) {
                console.log("creatMangoCourse(API):",res.data)
                return res.data
            }
        })
        .catch(e => {
            console.log(e);
        });
        return response
    },
    async manualEnrollMango(data){
      console.log(data)
      var response = await LMS.post(`api/ManualEnrollMango`,data, {timeout: 600000})
        .then(res => {
            if (res.data) {
              console.log("ManualEnrollMango(API):",res.data)
              return res.data
            }
        })
        .catch(e => {
            console.log(e);
        });
        return response
    },
    async manualSyncMango(data){
      console.log(data)
      var response = await LMS.post(`api/ManualSyncMango`,data, {timeout: 600000})
        .then(res => {
            if (res.data) {
              console.log("ManualSyncMango(API):",res.data)
              return res.data
            }
        })
        .catch(e => {
            console.log(e);
        });
        return response
    },
    async updateMangoCourse(data){
      console.log(data)
      var response = await LMS.post(`api/MangoUseOldCourse`,data, {timeout: 600000})
        .then(res => {
            if (res.data) {
              console.log("MangoUseOldCourse(API):",res.data)
              return res.data
            }
        })
        .catch(e => {
            console.log(e);
        });
        return response
    },
    async checkCanBeCreate(data){
      var response = await LMS.post(`api/CheckCanBeCreate`,data, {timeout: 600000})
        .then(res => {
            if (res.data) {
              console.log("checkCanBeCreate(API):",res.data)
              return res.data
            }
        })
        .catch(e => {
            console.log(e);
        });
        return response
    },
}